'use client'

import { useResizeObserver } from '@susu/headless-commerce/hooks/useResizeObserver'
import log from '@susu/log'
import type { ReactNode } from 'react'
import { useLayoutEffect } from 'react'

const setScreenHeightValues = () => {
  const root = window?.document?.documentElement

  // Need to calculate height like this, its a fix for iphone - its changing height dynamically - known issue.
  root.style.setProperty('--screen-inner-height', `${window?.innerHeight}px`)
  root.style.setProperty('--screen-outer-height', `${window?.outerHeight}px`)
}

export type RootTemplateProps = {
  children: ReactNode
}

export default function LocaleTemplate({ children }: RootTemplateProps) {
  try {
    useResizeObserver(setScreenHeightValues)
    useLayoutEffect(setScreenHeightValues, [])
    return children
  } catch (error: unknown) {
    log.error(error as Error)
  }
}
